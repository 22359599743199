<template>
    <Primitive :as="as" :as-child="asChild" :class="cn(buttonVariants({ variant, size, fill }), props.class)">
        <slot />
    </Primitive>
</template>
<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { Primitive, type PrimitiveProps } from "radix-vue";
import { type ButtonVariants, buttonVariants } from "~/components/ui";

interface Props extends PrimitiveProps {
    variant?: ButtonVariants["variant"];
    size?: ButtonVariants["size"];
    fill?: ButtonVariants["fill"];
    class?: HTMLAttributes["class"];
}

const props = withDefaults(defineProps<Props>(), {
    as: "button",
    size: "md",
    variant: "default",
    fill: "none",
    class: "",
});
</script>
